import styled from 'styled-components'

export default styled.div`
  height: 100%;
  overflow: auto;
  .select-site-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1270px;
    margin: 0 auto;
    padding-top: 30px;

    .project-container {
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.darkBackground};
        border-radius: 0;
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        max-height: 400px;
        height: 400px;
        background: ${(props) => props.theme.scrollbarThumbDark};
      }
      .project-wrapper {
        margin-top: 29px;
        .project-title-container {
          display: flex;
          padding-left: 35px;
          margin-bottom: 35px;
          .project-img {
            width: 55px;
            height: 55px;
            border-radius: 5px;
            background: ${(props) => props.theme.greyDisabled};
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
            margin-right: 25px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 5px;
            }
          }
          .project-title {
            width: 100%;
            font-size: 40px;
            color: white;
          }
        }
        .site-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;

          .empty-site-wrapper {
            height: 550px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              width: 50px;
              height: 37px;
              object-fit: contain;
              margin-bottom: 24px;
            }
            .empty-site-text {
              font-weight: bold;
              font-size: 24px;
              color: white;
              margin-bottom: 30px;
            }
            .add-site-btn {
              width: 300px;
            }
          }
        }
      }
      .separate-line {
        max-width: 1190px;
      }
      .black {
        background: ${(props) => props.theme.darkBackground};
        height: 620px;
        &:after {
          position: absolute;
          top: 0px;
          left: 0px;
          content: '';
          display: block;
          background-image: linear-gradient(
            90deg,
            rgba(117, 117, 117, 0.1) 0,
            rgba(117, 117, 117, 0.35) 25%,
            rgba(117, 117, 117, 0.35) 50%,
            rgba(117, 117, 117, 0.1) 75%
          );
        }
      }
      .no-site {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 45%;
        left: 45%;
        img {
          width: 50px;
          height: 37px;
        }
        .no-site-text {
          font-family: 'Prompt', sans-serif;
          font-size: 30px;
          font-weight: bold;
          color: white;
          margin-top: 24px;
          margin-bottom: 30px;
        }
        button {
          width: 300px;
          height: 40px;
          border-radius: 5px;
        }
      }
    }
  }
`
