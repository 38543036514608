/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SelectSiteStyled from './styledComponent'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import { withAuth0 } from '@auth0/auth0-react'
import * as selectSiteActions from './actions'
import * as loginActions from '../LoginPage/actions'
import SiteCard from '../../components/SiteCard/Loadable'
import SkeletonLoading from '../../components/SkeletonLoading/Loadable'
import DivideLine from '../../components/DivideLine/Loadable'
import Button from '../../components/Button/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'

import fetchErrorHandler from '../../utils/fetchErrorHandler'
import emptySiteIcon from '../../asset/images/emptyCase.svg'
import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE, FRONTEND_ROUTES } from '../../utils'

import MESSAGE from './message'
let timing

export class SelectSitePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      showAlertSiteNoCameraModal: false,
      lastSyncTime: moment(),
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    }
  }

  componentDidMount() {
    this.props.getListSite()
    this.initialIntervalLoadData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loadSiteStatus === FETCH_STATUS_IDLE && this.props.loadSiteStatus === FETCH_STATUS_REQUEST) {
      this.setState({
        isLoading: true
      })
    } else if (prevProps.loadSiteStatus === FETCH_STATUS_REQUEST && this.props.loadSiteStatus === FETCH_STATUS_SUCCESS) {
      this.setState({
        isLoading: false,
        lastSyncTime: moment()
      })
    } else if (prevProps.loadSiteStatus === FETCH_STATUS_REQUEST && this.props.loadSiteStatus === FETCH_STATUS_FAILURE) {
      this.setState({
        isLoading: false
      })
      this.handleLoadListSiteFailure()
    }
  }

  componentWillUnmount() {
    clearInterval(timing)
  }

  initialIntervalLoadData() {
    timing = setInterval(() => {
      this.props.getListSite()
    }, process.env.INTERVAL_CALL_API_MINUTES * 60 * 1000)
  }

  handleLoadListSiteFailure() {
    const statusCode = this.props.loadSiteError.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  forceLogin = () => {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language || navigator.language.toUpperCase()]
    return msg[property]
  }

  onSiteClick(siteId, projectID) {
    this.props.history.push(`${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${siteId}/dashboard`)
  }

  handleAddNewSiteBtnClicked = (projectID) => {
    this.props.history.push(`${FRONTEND_ROUTES.projectManagementRoute}/${projectID}`)
  }

  setShowAlertModalNoCameraSite = () => {
    this.setState({
      showAlertSiteNoCameraModal: true
    })
  }

  setCloseAlertModalNoCameraSite = () => {
    this.setState({
      showAlertSiteNoCameraModal: false
    })
  }

  handleNoCameraSiteClicked = () => {
    this.setShowAlertModalNoCameraSite()
  }

  renderSites(sites, projectID) {
    let output = null
    if (sites.length > 0) {
      output = sites.map((site) => {
        const onClick = site.all_camera === 0 ? this.handleNoCameraSiteClicked : () => this.onSiteClick(site.site_id, projectID)
        return <SiteCard key={site.site_id} siteObject={site} onSiteClick={onClick} />
      })
    } else {
      const newSiteBtn = (
        <Button className="add-site-btn" text={this.getMessage('add_site_btn')} onClick={() => this.handleAddNewSiteBtnClicked(projectID)} />
      )
      output = (
        <div className="empty-site-wrapper">
          <img src={emptySiteIcon} alt="" />
          <div className="empty-site-text text-style">{this.getMessage('nothing_to_show')}</div>
          {this.props.currentUser?.admin ? newSiteBtn : null}
        </div>
      )
    }
    return output
  }

  handleCreateNewProjectBtnClicked = () => {
    this.props.history.push(FRONTEND_ROUTES.projectManagementRoute)
  }

  getNothingToShowContainer() {
    const newProjectBtn = <Button id="add-project-btn" onClick={this.handleCreateNewProjectBtnClicked} text={this.getMessage('add_project_btn')} />
    return (
      <div className="no-site">
        <img src={emptySiteIcon} alt="" />
        <div className="no-site-text">{this.getMessage('nothing_to_show')}</div>
        {this.props.currentUser && this.props.currentUser.admin ? newProjectBtn : null}
      </div>
    )
  }

  renderProject() {
    let output = this.getNothingToShowContainer()
    if (this.props.listProject.length > 0) {
      output = this.props.listProject.map((project, index) => {
        return (
          <div key={project.project_id}>
            <div className="project-wrapper">
              <div className="project-title-container">
                <div className="project-img">{project.pre_encode_image ? <img src={project.pre_encode_image} alt="" /> : null}</div>
                <div className="project-title text-style prevent-text-overflow">{project.name}</div>
              </div>
              <div className="site-wrapper">{this.renderSites(project.sites, project.project_id)}</div>
            </div>
            {index !== this.props.listProject.length - 1 && <DivideLine className="separate-line" />}
          </div>
        )
      })
    }
    return output
  }

  getContent() {
    let output = <SkeletonLoading className="black" />
    if (this.props.loadSiteStatus !== FETCH_STATUS_REQUEST) {
      output = this.renderProject()
    }
    return output
  }

  getAlertModalSiteNoCamera() {
    let output = null
    if (this.state.showAlertSiteNoCameraModal) {
      output = (
        <ModalNotification
          className={'no-camera-modal'}
          title={this.getMessage('no_camera_alert_dialog_title')}
          message={this.getMessage('no_camera_alert_dialog_message')}
          onOverLayClose={this.setCloseAlertModalNoCameraSite}
        />
      )
    }
    return output
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <SelectSiteStyled>
        <div className="select-site-container">
          <div className="project-container">{this.getContent()}</div>
        </div>
        {this.getAlertModalSiteNoCamera()}
        {this.getFailureModal()}
      </SelectSiteStyled>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    listProject: state.selectSitePage.listProject,
    loadSiteStatus: state.selectSitePage.loadSiteStatus.fetchStatus,
    loadSiteError: state.selectSitePage.loadSiteStatus.error,
    currentUser: state.loginPage.loginAPI.userData,
    loginStatus: state.loginPage.loginAPI.fetchStatus
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, selectSiteActions, loginActions), dispatch)
}

SelectSitePage.propTypes = {
  listProject: PropTypes.arrayOf(
    PropTypes.shape({
      active_camera: PropTypes.number,
      all_camera: PropTypes.number,
      name: PropTypes.string,
      project_id: PropTypes.number,
      site_id: PropTypes.number
    })
  ),
  loadSiteStatus: PropTypes.string,
  currentUser: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    language: PropTypes.string
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(SelectSitePage))
