const MESSAGE = {
  EN: {
    select_site_header: 'Select Site',
    no_camera_alert_dialog_title: 'No Camera',
    no_camera_alert_dialog_message: 'There is no camera in this site.',
    nothing_to_show: 'Nothing to Show',
    add_project_btn: 'Add New Project',
    add_site_btn: 'Add New Site',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    select_site_header: 'เลือกไซต์',
    no_camera_alert_dialog_title: 'ไม่มีกล้อง',
    no_camera_alert_dialog_message: 'ไม่มีกล้องในไซต์นี้',
    nothing_to_show: 'ไม่มีข้อมูล',
    add_project_btn: 'เพิ่มโปรเจกใหม่',
    add_site_btn: 'เพิ่มไซต์ใหม่',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}

export default MESSAGE
